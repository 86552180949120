import React from "react";
import styles from "./cart.module.css";
import { useHistory } from "react-router-dom";
import HeaderBox from "../Home/HeaderBox";
import { useEffect } from "react";
import { useState } from "react";
import { formatPrice } from "../commons/helpers";
export default function Cart() {
  let history = useHistory();
  const handleVariant = () => {
    localStorage.setItem("deliveryType", isLand ? "Land" : "Air");
    history.push("/checkout");
  };
  const handleProduct = () => {
    history.push("/");
  };
  const [cartItem, setCartItem] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [isLand, setisLand] = useState(true);
  const [isAir, setisAir] = useState(false);

  const handleQuantity = (e, i, selectedArticle) => {
    let newArr = [...quantity];
    newArr[i] = e.target.value;

    if (e.target.value <= cartItem[i].articleObj.articleCount) {
      if (e.target.value > 0) {
        console.log(newArr, e.target.value);
        setQuantity(newArr);
        console.log(selectedArticle);

        let mainArrayItems = JSON.parse(localStorage.getItem("mainarry"));
        let mainArrayFilteredItems = mainArrayItems.filter(
          (item) => item.ARTICLE_NO == selectedArticle
        );
        mainArrayFilteredItems[0].articleObjCount = e.target.value;
        let combinedArray = [...mainArrayItems, ...mainArrayFilteredItems];
        localStorage.setItem(
          "mainarry",
          JSON.stringify([...new Set(combinedArray)])
        );
        window.location.reload();
        console.log(mainArrayFilteredItems);
        localStorage.setItem("quantity", newArr);
      }
    } else {
      alert(
        `Only ${cartItem[i].articleObj.articleCount} Of these available in stock`
      );
    }
  };
  console.log()

  const handleLand = () => {
    setisLand(true);
    setisAir(false);
  };

  const handleAir = () => {
    setisLand(false);
    setisAir(true);
  };

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  // const [clear, setClear] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("mainarry")) {
      setCartItem(
        removeDuplicates(
          JSON.parse(localStorage.getItem("mainarry")),
          "ITEM_ID"
        )
      );
      const count = {};

      for (const element of JSON.parse(localStorage.getItem("mainarry"))) {
        if (count[element["ITEM_ID"]]) {
          count[element["ITEM_ID"]] = element.articleObjCount;
        } else {
          count[element["ITEM_ID"]] = element.articleObjCount;
        }
      }

      const initialQuantityArr = [];

      for (let i = 0; i < Object.keys(count).length; i++) {
        //  let myE = {
        //     target: {
        //       value: count[Object.keys(count)[i]]
        //     }
        //  }
        //  console.log(myE, i, "myE")
        //  handleQuantity(myE, i)
        initialQuantityArr[i] = count[Object.keys(count)[i]];
        // setQuantity([...quantity, count[Object.keys(count)[i]]])
      }

      setQuantity(initialQuantityArr);
    }
  }, []);

  const subtotal = cartItem.reduce(
    (prevValue, element, i) =>
      prevValue + element.price * (quantity[i] ? quantity[i] : 1),
    0
  );


  return (
    <div className={styles.restBanner}>
      <HeaderBox />
      <div className={`${styles.cartContainer} `}>
        <div className={styles.mainContainer}>
          <table className={styles.tablecontainer}>
            <tr className={styles.productContainerTitle}>
              <th>PRODUCT</th>
              <th>IMAGE</th>
              <th>ITEM NAME</th>
              <th>ARTICLE ID</th>
              <th>QUANTITY</th>
              <th>UNIT PRICE</th>
              <th>COST</th>
              <th></th>
            </tr>
            {cartItem.length == 0 && (
              <tr className={styles.productContainer}>
                <td colSpan={8}>No Items in Cart</td>
              </tr>
            )}
            {cartItem.map((element, i) => (
              <>
                <tr className={styles.productContainer}>
                  <td>{element.name}</td>
                  <td>
                    <img width="50%" src={element.imageUrl} alt="" />
                  </td>
                  <td>{element.ITEM_ID}</td>
                  <td>{element.ARTICLE_NO}</td>
                  <td>
                    <input
                      type="number"
                      className={styles.quantity}
                      value={quantity[i] ? quantity[i] : 1}
                      onChange={(e) => handleQuantity(e, i, element.ARTICLE_NO)}
                    />
                  </td>
                  <td>{formatPrice(element.price)}</td>
                  <td>
                    {formatPrice(
                      element.price * (quantity[i] ? quantity[i] : 1)
                    )}
                  </td>

                  <td>
                    <button
                      onClick={() => {
                        JSON.parse(localStorage.getItem("mainarry")).length > 1
                          ? localStorage.setItem(
                              "mainarry",
                              JSON.stringify(
                                JSON.parse(
                                  localStorage.getItem("mainarry")
                                ).filter(
                                  (el) => el.ARTICLE_NO !== element.ARTICLE_NO
                                )
                              )
                            )
                          : localStorage.removeItem("mainarry");
                        window.location.reload();
                      }}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
                <br />
              </>
            ))}
          </table>
          <hr />
          <div className={styles.deliveryCheckout}>
            <div>
              <h3>DELIVERY TIME FRAME : </h3>
              <p>
                Items will be dispatched within 2 working days.
                {/* Out of Stock: Items will be dispatched after 3 weeks. */}
              </p>
            </div>
            <div>
              <div className={styles.deliveryCheckoutTotal}>
                <table className={styles.tablecontainer}>
                  <tbody>
                    <tr className={styles.deliveryCheckoutStyle}>
                      <td>SUBTOTAL </td>
                      <td>--{">"}</td>
                      <td style={{textAlign:"right"}}>{cartItem && formatPrice(subtotal)}</td>
                    </tr>
                    <tr className={styles.deliveryCheckoutStyle}>
                      <td>GST @ 18% </td>
                      <td>--{">"}</td>
                      <td style={{textAlign:"right"}}>
                        {cartItem && formatPrice((subtotal * 0.18).toFixed(0))}
                      </td>
                    </tr>
                    <hr />
                    <tr className={styles.deliveryCheckoutStyle}>
                      <td>GRAND&nbsp;TOTAL</td>
                      <td>--{">"}</td>
                      <td style={{ textAlign:"right", fontWeight: "bold" }}>
                        {cartItem &&
                          formatPrice(
                            parseFloat(subtotal) +
                              parseFloat((subtotal * 0.18).toFixed(0))
                          )}
                      </td>
                    </tr>
                    <hr />
                    <div
                      class="form-check"
                      onClick={handleAir}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={isAir}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        class="form-check-label"
                        for="flexRadioDefault1"
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      >
                        By Air
                      </label>
                    </div>
                    <div
                      class="form-check"
                      onClick={handleLand}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={isLand}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        class="form-check-label"
                        for="flexRadioDefault2"
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      >
                        By Land
                      </label>
                    </div>
                  </tbody>
                </table>

                <p className={styles.flightCharge}>
                  Flight Charges Extra - TO PAY
                </p>
              </div>
            </div>
          </div>
          <div className={styles.proceedBtn}>
            <small>
              <button onClick={handleProduct}>ADD MORE TO CART</button>
              <button
                onClick={() => cartItem?.length != 0 && handleVariant()}
                style={
                  cartItem?.length == 0
                    ? { background: "grey", cursor: "not-allowed" }
                    : { background: "#275207" }
                }
              >
                PROCEED TO PAYMENT
              </button>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <p>
SubTotal - <span>₹26,400.00 </span>
</p>
<p>
GST (18%) - <span>₹26,400.00 </span>
</p>
<p>
SHIPPING COST <br />
(COD OR CASH ON
<br /> DELIVERY - ₹ 250/) -<span>₹26,400.00 </span>
</p>
<p>
GRAND TOTAL <span> ₹31,152.00</span>
</p>
<p>ADDITIONAL CHARGES APPLY ON SHIPPING</p> */
}
