import React, { useState } from "react";
import { Link } from "react-router-dom";
import { In, Ge } from "react-flags-select";

const Header = () => {
  const [countryLnaguage, setCountryLnaguage] = useState(null);
  const handleChange = (e) => {
    setCountryLnaguage(e.target.value);
  };
  return (
    <>
      <header className="bg-transparent needXhidden">
        <div className="container">
          <div className="row g-5">
            <div className="offset-md-6 col-md-6 float-end">
              <p className="mb-0 text-sm-end text-center">
                {/* <Link
                  to="#"
                  className="btn btn-sm btn-success"
                  id="catalogue-btn"
                >
                  Catalog
                </Link> */}
                <button>Login</button>
                <button>Register</button>
                {/* <span className="mb-0 text-sm-end text-center ms-3 me-2">
                  {(countryLnaguage === null && <In />) ||
                    (parseInt(countryLnaguage) === 0 && <In />) ||
                    (parseInt(countryLnaguage) === 1 && <Ge />)}
                </span> */}
                {/* <select id="catalog-flag" onChange={handleChange}>
                  <option value="0">IN</option>
                  <option value="1">GE</option>
                </select> */}
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
