import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { formatPrice } from "../commons/helpers";
import HeaderBox from "../Home/HeaderBox";
import styles from "./OrderProduct.module.css";
import axios from "axios";

const OrderProduct = () => {
  let history = useHistory();

  var x = JSON.parse(localStorage.getItem("mainarry"))
 
  let z  =  x.map(element => `Article No:${element.ARTICLE_NO} ,Item Id :${element.ITEM_ID}, Quntity:${element.articleObjCount}  ,Unit Price: ₹${element.price}`)
  

  const [cartItem, setCartItem] = useState([]);
  const [quantity, setQuantity] = useState([]);

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  // const [clear, setClear] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("mainarry")) {
      setCartItem(
        removeDuplicates(
          JSON.parse(localStorage.getItem("mainarry")),
          "ITEM_ID"
        )
      );
      const count = {};

      for (const element of JSON.parse(localStorage.getItem("mainarry"))) {
        if (count[element["ITEM_ID"]]) {
          count[element["ITEM_ID"]] += 1;
        } else {
          count[element["ITEM_ID"]] = 1;
        }
      }

      const initialQuantityArr = [];

      for (let i = 0; i < Object.keys(count).length; i++) {
        //  let myE = {
        //     target: {
        //       value: count[Object.keys(count)[i]]
        //     }
        //  }
        //  console.log(myE, i, "myE")
        //  handleQuantity(myE, i)
        initialQuantityArr[i] = count[Object.keys(count)[i]];
        // setQuantity([...quantity, count[Object.keys(count)[i]]])
      }

      setQuantity(initialQuantityArr);
      if (localStorage.getItem("quantity")) {
        setQuantity(JSON.parse(`[${localStorage.getItem("quantity")}]`));
      }
    }
  }, []);

  const subtotal = cartItem.reduce(
    (prevValue, element, i) =>
      prevValue + element.price * (quantity[i] ? quantity[i] : 1),
    0
  );

  const gstTotal = subtotal + parseFloat((subtotal * 0.18).toFixed(0))



  // Razor Pay code here

  const userData = JSON.parse(localStorage.getItem("userData"))

  const handlePaymentSuccess = (res) => {
    const data = {
      inventories: cartItem.map((item, i) => {
        let obj = {
          articleId: item.articleObj.articleId,
          articleCount: quantity[i],
          effectiveSaleDateTime: new Date().toISOString(),
          correlationId: item.articleObj.correlationId,
        };
        return obj;
      }),
      refId: res.razorpay_payment_id,
      transactionType: "SOLD",
    };
    // console.log(data, "req sent", res)
    axios
      .post(
        "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/saleble_articles/inventories",
        data
      )
      .then((response) => {
        var payload = {
          service_id: "service_qgamwwn",
          template_id: "template_fbou472",
          user_id: "GUYCaR9xSYDUuBsdH",

          template_params: {
            customer_name:`Name :${userData.FirstName} ${userData.LastName}`,
            company_name:`Company Name : ${userData.CompanyName}`,
            mobile_number:`Mobile No : ${userData.Phone}`,
            customer_email:`Customer Email : ${userData.Email}`,
            address:`Address : ${userData.Address}`,
            city:`City: ${userData.City}`,
            state:`State : ${userData.State}`,
            zip_code:`Zip Code : ${userData.PostalCode}`,
            gst_number:`Gst Number :${userData.GSTNumber}`,
            delivery_type:`Delivery Type :${localStorage.getItem('deliveryType')}`,
            item_details:`Produt Detail : ${z}`,
            transaction_id:`Transaction Id :${res.razorpay_payment_id}`,
            transaction_amount:`Transaction_Amount : ${formatPrice(gstTotal + 250)}`,
            secondary_address:`
              ${userData.isDifferentAddress &&
              `
                Ship to different Address: {
                  Company Name: ${userData.CompanyNameTwo},
                  mobile number: ${userData.PhoneTwo},
                  address: ${userData.AddressTwo},
                  city: ${userData.CityTwo},
                  state: ${userData.StateTwo},
                  ZIP/Postal Code: ${userData.PostalCodeTwo},
                  GST Number: ${userData.GSTNumberTwo}.
                }
                `
              }
            `,
            mail_title: `
              New Order from Sphoorti Online Store
            `,
          },
        };
        axios({
          method: "post",
          url: "https://api.emailjs.com/api/v1.0/email/send",
          data: JSON.stringify(payload), // you are sending body instead
          headers: {
            // 'Authorization': `bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        alert("Order Successfull")
        history.push("/")
        localStorage.clear()
      });
  };

  console.log(cartItem, "cart", JSON.parse(localStorage.getItem("userData")));

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_test_C72Sv4nEj0YmsA",
      // also remove TEST from line no. 118
      currency: "INR",
      amount: amount * 100,
      name: "Sphoorti",
      description: "Thanks for Purchasing",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1280px-Test-Logo.svg.png",

      handler: function (response) {
        handlePaymentSuccess(response);
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      <div className={styles.restBanner}>
        <HeaderBox />
        <div className={`${styles.checkOutContainer} container`}>
          <div className={styles.mainContainer}>
            <h3 align="center">PAYMENT</h3>
            <div className={styles.detailContainer}>
              {cartItem?.map((element, i) => (
                <>
                  <table className={styles.finaldetail}>
                    <tbody>
                      <p>ITME - {i + 1}</p>
                      <tr>
                        <td>ITEM-ID</td>
                        <td>:</td>
                        <td>{element.ITEM_ID}</td>
                      </tr>
                      <br />
                      <tr>
                        <td>QUANTITY</td>
                        <td>:</td>
                        <td>{quantity[i]}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </>
              ))}
              {/* <table className={styles.finaldetail}>
                <tbody>
                  <p>ITME-2</p>
                  <tr>
                    <td>ITEM-ID</td>
                    <td>:</td>
                    <td>SD 48 07</td>
                  </tr>
                  <br />
                  <tr>
                    <td>QUANTITY</td>
                    <td>:</td>
                    <td>2</td>
                  </tr>
                </tbody>
              </table>
              <br /> */}
              <table className={styles.finaldetailAmount}>
                <p>AMOUNT TO PAY:</p>
                <tr>
                  <td>SUBTOTAL</td>
                  <td>:</td>
                  <td>{cartItem && formatPrice(subtotal)}</td>
                </tr>
                <br />
                <tr>
                  <td>SUBTOTAL WITH 18% GST</td>
                  <td>:</td>
                  <td>{cartItem && formatPrice(gstTotal)}</td>
                </tr>
                <br />
                {/* <tr>
                  <td>DELIVERY COST</td>
                  <td>:</td>
                  <td>₹0.00</td>
                </tr> */}
                <br />
                <tr>
                  <td>GRAND TOTAL</td>
                  <td>:</td>
                  <td>{cartItem && formatPrice(gstTotal)}</td>
                </tr>
              </table>
              <br />
              <div className={styles.proceedBtnContainer}>
                <div>
                  <button
                    className={styles.proceedBtn}
                    onClick={() => displayRazorpay(gstTotal + 250)}
                  >
                    PAY NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderProduct;
