import React, { useEffect } from "react";
import styles from "./ProductStyle.module.css";
// import { useHistory } from "react-router-dom";
import HeaderBox from "../Home/HeaderBox";
import { AiOutlineShoppingCart, AiOutlineFilePdf } from "react-icons/ai";
import { useState } from "react";
import axios from "axios";
function ProductPage({ Product, ProductData, setProductData }) {
  const [tableShow, setTableShow] = useState(false);
  const [availItems, setAvailItems] = useState([]);
  useEffect(() => {
    localStorage.getItem("productData") &&
      setProductData(JSON.parse(localStorage.getItem("productData")));
    if (ProductData) {
      setTableShow(true);
      // console.log(ProductData);
    } else {
      console.log("not Done");
    }
  }, []);

  useEffect(() => {
    const ArticleNoArr = ProductData?.table?.value.map((item) =>
      item[ProductData.table.identifier.indexOf("ARTICLE_NO")].replace(
        /\s+/g,
        ""
      )
    );
    // console.log(ArticleNoArr, "articleno");
    // const ArticleNoArr = ["SD58133", "SD48049", "SD58129", "SD482363"];        //if need to test
    axios
      .post(
        "https://sphoortitoolscatalogapi20211217232946.azurewebsites.net/tools/api/v1/catalogs/products/saleble_articles/article-inventories",
        ArticleNoArr
      )
      .then((res) => {
        const Arr = [];
        ArticleNoArr.map(
          (el, i) => (Arr[i] = res.data.data.filter((r) => r.articleId === el))
        );
        // console.log(Arr, "arrrrrr");
        setAvailItems(Arr);
      });
  }, [ProductData]);

  // let history = useHistory();

  const document =
    '"https://ik.imagekit.io/syeovulacjk/sptools/asset/docs/pdf/SD7002.pdf"';

  const handleVariant = (item, identifier, image, name, price, articleObj) => {
    let articleObjCount = 1;
    let mainArrayItems = JSON.parse(localStorage.getItem("mainarry"));
    let checkArticleId = (obj) => obj.ARTICLE_NO === articleObj.articleId;

    if (mainArrayItems !== null) {
      if (mainArrayItems.some(checkArticleId)) {
        let mainArrayFilteredItems = mainArrayItems.filter(
          (item) => item.ARTICLE_NO == articleObj.articleId
        );

        mainArrayFilteredItems[0].articleObjCount += 1;

        if (mainArrayFilteredItems.length > 0) {
          let combinedArray = [...mainArrayItems, ...mainArrayFilteredItems];

          localStorage.setItem(
            "mainarry",
            JSON.stringify([...new Set(combinedArray)])
          );
          window.location.reload();
        }
        console.log("old");
      }
    }
    if (!mainArrayItems?.some(checkArticleId)) {
      let pushingObject = {
        imageUrl: image,
        name: name,
        price: price,
        articleObj: articleObj,
        articleObjCount: articleObjCount,
      };

      for (let i = 0; i < identifier.length; i++) {
        pushingObject[identifier[i]] = item[i];
      }

      localStorage.setItem(
        "mainarry",
        JSON.stringify(
          localStorage.getItem("mainarry")
            ? [...JSON.parse(localStorage.getItem("mainarry")), pushingObject]
            : [pushingObject]
        )
      );
      window.location.reload();
    }
    // if (localStorage.getItem("mainarry") === null) {
    //   if (!mainArrayItems.some(checkArticleId)) {
    //     let pushingObject = {
    //       imageUrl: image,
    //       name: name,
    //       price: price,
    //       articleObj: articleObj,
    //       articleObjCount: articleObjCount,
    //     };

    //     for (let i = 0; i < identifier.length; i++) {
    //       pushingObject[identifier[i]] = item[i];
    //     }

    //     localStorage.setItem(
    //       "mainarry",
    //       JSON.stringify(
    //         localStorage.getItem("mainarry")
    //           ? [...JSON.parse(localStorage.getItem("mainarry")), pushingObject]
    //           : [pushingObject]
    //       )
    //     );
    //     window.location.reload();
    //   }
    // }

    // old code

    // // history.push("/cart");
    // window.location.reload();
  };

  return (
    <>
      <div className={styles.restBanner}>
        <HeaderBox />
        <div className={`${styles.productContainer} container`}>
          <div className="row">
            <div className={styles.mainContainer}>
              <h1 className={styles.mainTitle}>{ProductData.name}</h1>
              <div className={styles.productImg}>
                <div className={styles.image}>
                  <img src={ProductData.image} alt="product" />
                </div>
                <div className={styles.productDesc}>
                  <img src={ProductData.cadImage} alt="" />
                  {/* <p className={styles.productDescTitle}>Features</p> */}
                  {/* <ul>
                    <li>VDI tool holders are made as per DIN 69880.</li>
                    <li>
                      Tool Holders are made by toughened steel C-45 as well as
                      case hardened steel 20MnCr5.
                    </li>
                    <li>
                      Our standard supply will be in toughened steel C-45 for
                      from A,B,C,D,E1,E2&Z varieties.
                    </li>
                    <li>
                      Case hardened steel 20MnCr5 for from E4&F varieties.
                    </li>
                    <li>Our standard tool holders are fully blackened.</li>
                    <li>
                      Shank white,teeth white,tool holder seating surface white
                      will be given on request.
                    </li>
                    <li>
                      The tool holder shank portion are heat treated to 58+/-2
                      HRC,and the teeths are ground.
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className={styles.textAvailable}>
                <p>
                  {" "}
                  {/* Tool Holders are available for purchase in increments
                  of 1 */}
                </p>
              </div>
              <div className={styles.tableValues}>
                <table>
                  <thead>
                    <tr>
                      {tableShow &&
                        ProductData.table.identifier.map((item, i) => {
                          // console.log(item);
                          return <th>{item}</th>;
                        })}
                      <th>EX STOCK QTY. </th>
                      <th> DOWNLOAD </th>
                      <th>Add to Cart</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableShow &&
                      ProductData.table.value.map((item, rowIndex) => {
                        return (
                          <>
                            <tr>
                              {item
                                .filter(
                                  (el, index) =>
                                    index !==
                                    ProductData.table.identifier.indexOf(
                                      "PRICE"
                                    )
                                )
                                .map((items) => {
                                  return <td>{items} </td>;
                                })}
                              <td>
                                {/* ₹
                                {
                                  item[
                                    ProductData.table.identifier.indexOf(
                                      "PRICE"
                                    )
                                  ]
                                } */} NA
                              </td>
                              <td style={{ fontWeight: "bold" }}>
                                {" "}
                                {availItems[rowIndex]?.[0]
                                  ? availItems[rowIndex]?.[0].articleCount
                                  : 0}
                              </td>
                              <td>
                                <AiOutlineFilePdf onClick={document} />
                              </td>
                              <td>
                                <AiOutlineShoppingCart
                                  // onClick={() =>
                                  //   availItems[rowIndex]?.[0] &&
                                  //   availItems[rowIndex]?.[0].articleCount >
                                  //     0 &&
                                  //   handleVariant(
                                  //     item,
                                  //     ProductData.table.identifier,
                                  //     ProductData.image,
                                  //     ProductData.name,
                                  //     item[
                                  //       ProductData.table.identifier.indexOf(
                                  //         "PRICE"
                                  //       )
                                  //     ],
                                  //     availItems[rowIndex]?.[0]
                                  //   )
                                  // }
                                  style={
                                    // !(
                                    //   availItems[rowIndex]?.[0] &&
                                    //   availItems[rowIndex]?.[0].articleCount > 0
                                    // ) && 
                                    {
                                      color: "grey",
                                      cursor: "not-allowed"
                                    }
                                  }
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPage;
