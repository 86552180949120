import React, { useLayoutEffect, useRef, useState } from "react";
import styles from "./checkout.module.css";
import HeaderBox from "../Home/HeaderBox";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import emailjs from '@emailjs/browser';
import { regexType } from "../constant";
import FormModal from "../UI/Modal/FormModal";
export default function Checkout() {
  const form = useRef();
  const [close, setClose] = useState(false)
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qgamwwn', 'template_fbou472', form.current, '0ugtleXRRE5RDC6nw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const history = useHistory();


  const [Counter, setCounter] = useState(0);

  const buttonHnadler = () => {
    const userData = {
      FirstName,
      LastName,
      CompanyName,
      Address,
      AddressTwo,
      GSTNumber,
      GSTNumberTwo,
      Email,
      EmailTwo,
      Phone,
      PhoneTwo,
      State,
      StateTwo,
      City,
      CityTwo,
      PostalCode,
      PostalCodeTwo,
      CompanyNameTwo,
      isDifferentAddress,
    };
    const isFilled =
      FirstName &&
      LastName &&
      CompanyName &&
      Address &&
      GSTNumber &&
      GSTNumber?.match(regexType.GST_NUMBER_VALIDATION) &&
      Email &&
      Email?.match(regexType.EMAIL_ID) &&
      Phone &&
      Phone?.match(regexType.PHONE_NUMBER) &&
      State &&
      City &&
      PostalCode &&
      PostalCode?.match(regexType.PINCODE) &&
      (isDifferentAddress
        ? CompanyNameTwo &&
          PostalCodeTwo &&
          CityTwo &&
          StateTwo &&
          PhoneTwo &&
          EmailTwo &&
          GSTNumberTwo &&
          AddressTwo
        : true);
    if (isFilled) {
      localStorage.setItem("userData", JSON.stringify(userData));
      history.push("./orderproduct");
    } else {
      // alert("Please fill all the details first");
      setClose(true)
      setCounter((state) => state + 1);
    }
  };

  const [FirstName, setFirstName] = useState(null);
  const [FirstNameErr, setFirstNameErr] = useState(false);
  const [LastName, setLastName] = useState(null);
  const [LastNameErr, setLastNameErr] = useState(false);
  const [CompanyName, setCompanyName] = useState(null);
  const [CompanyNameErr, setCompanyNameErr] = useState(false);
  const [CompanyNameTwo, setCompanyNameTwo] = useState(null);
  const [CompanyNameTwoErr, setCompanyNameTwoErr] = useState(null);
  const [Address, setAddress] = useState(null);
  const [AddressErr, setAddressErr] = useState(false);
  const [AddressTwo, setAddressTwo] = useState(null);
  const [AddressTwoErr, setAddressTwoErr] = useState(false);
  const [GSTNumber, setGSTNumber] = useState(null);
  const [GSTNumberErr, setGSTNumberErr] = useState(false);
  const [GSTNumberTwo, setGSTNumberTwo] = useState(null);
  const [GSTNumberTwoErr, setGSTNumberTwoErr] = useState(null);
  const [Email, setEmail] = useState(null);
  const [EmailErr, setEmailErr] = useState(null);
  const [EmailTwo, setEmailTwo] = useState(null);
  const [EmailTwoErr, setEmailTwoErr] = useState(null);
  const [Phone, setPhone] = useState(null);
  const [PhoneErr, setPhoneErr] = useState(null);
  const [PhoneTwo, setPhoneTwo] = useState(null);
  const [PhoneTwoErr, setPhoneTwoErr] = useState(false);
  const [State, setState] = useState(null);
  const [StateErr, setStateErr] = useState(null);
  const [StateTwo, setStateTwo] = useState(null);
  const [StateTwoErr, setStateTwoErr] = useState(false);
  const [City, setCity] = useState(null);
  const [CityErr, setCityErr] = useState(null);
  const [CityTwo, setCityTwo] = useState(null);
  const [CityTwoErr, setCityTwoErr] = useState(false);
  const [PostalCode, setPostalCode] = useState(null);
  const [PostalCodeErr, setPostalCodeErr] = useState(null);
  const [PostalCodeTwo, setPostalCodeTwo] = useState(null);
  const [PostalCodeTwoErr, setPostalCodeTwoErr] = useState(null);
  const [isDifferentAddress, setisDifferentAddress] = useState(false);

  const handleChekBox = (e) => {
    setisDifferentAddress(e.target.checked);
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };
  const handleCompanyNameTwo = (e) => {
    setCompanyNameTwo(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleAddressTwo = (e) => {
    setAddressTwo(e.target.value);
  };
  const handleGSTNumber = (e) => {
    setGSTNumber(e.target.value);
  };
  const handleGSTNumberTwo = (e) => {
    setGSTNumberTwo(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleEmailTwo = (e) => {
    setEmailTwo(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const handlePhoneTwo = (e) => {
    setPhoneTwo(e.target.value);
  };
  const handleState = (e) => {
    setState(e.target.value);
  };
  const handleStateTwo = (e) => {
    setStateTwo(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };
  const handleCityTwo = (e) => {
    setCityTwo(e.target.value);
  };
  const handlePostalCode = (e) => {
    setPostalCode(e.target.value);
  };
  const handlePostalCodeTwo = (e) => {
    setPostalCodeTwo(e.target.value);
  };

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setFirstNameErr(!!!FirstName);
    setLastNameErr(!!!LastName);
    setCompanyNameErr(!!!CompanyName);
    setAddressErr(!!!Address);
    setStateErr(!!!State);
    setCityErr(!!!City);
    setPostalCodeErr(!!!PostalCode || !PostalCode?.match(regexType.PINCODE));
    setGSTNumberErr(
      !!!GSTNumber || !GSTNumber?.match(regexType.GST_NUMBER_VALIDATION)
    );
    setEmailErr(!!!Email || !Email?.match(regexType.EMAIL_ID));
    setPhoneErr(!!!Phone || !Phone?.match(regexType.PHONE_NUMBER));
    setCompanyNameTwoErr(!!!CompanyNameTwo);
    setAddressTwoErr(!!!AddressTwo);
    setStateTwoErr(!!!StateTwo);
    setCityTwoErr(!!!CityTwo)
    setPostalCodeTwoErr(!!!PostalCodeTwo || !PostalCodeTwo?.match(regexType.PINCODE));
    setGSTNumberTwoErr(!!!GSTNumberTwo || !GSTNumberTwo?.match(regexType.GST_NUMBER_VALIDATION))
    setEmailTwoErr(!!!EmailTwo || !EmailTwo?.match(regexType.EMAIL_ID))
    setPhoneTwoErr(!!!PhoneTwo || !PhoneTwo?.match(regexType.PHONE_NUMBER))
  }, [
    Counter,
    FirstName,
    LastName,
    CompanyName,
    Address,
    State,
    City,
    PostalCode,
    GSTNumber,
    Email,
    Phone,
    CompanyNameTwo,
    AddressTwo,
    StateTwo,
    CityTwo,
    PostalCodeTwo,
    GSTNumberTwo,
    EmailTwo,
    PhoneTwo
  ]);
  
  
  const cloaseHandler = () => {
    setClose(false)
  }



  return (
    <>
      <div className={styles.restBanner}>
        <HeaderBox />
        {close && <FormModal onClick={cloaseHandler}/>}
        <div className={`${styles.checkOutContainer} container`}>
          <div className="row">
            <div></div>
            <div className={styles.mainContainer}>
              <form ref={form} onSubmit={sendEmail}>
                <div className={styles.formMainContainer}>
                  <div className={styles.billingAddress}>
                    <div className={styles.billingAddressName}>
                      <h3>BILLING ADDRESS</h3>
                    </div>
                    <div className={styles.twoColumn}>
                      <div className={styles.formField}>
                        <label>First Name</label>
                        <input
                          type="text"
                          onChange={handleFirstName}
                          value={FirstName}
                          name="user_name"
                        />
                        {FirstNameErr && (
                          <p style={{ color: "red" }}>
                            Please Enter First Name
                          </p>
                        )}
                      </div>
                      <div className={styles.formField}>
                        <label>Last Name</label>
                        <input
                          type="text"
                          onChange={handleLastName}
                          value={LastName}
                        />
                        {LastNameErr && (
                          <p style={{ color: "red" }}>Please Enter last Name</p>
                        )}
                      </div>
                    </div>
                    <div className={styles.formField}>
                      <label>Company</label>
                      <input
                        type="text"
                        onChange={handleCompanyName}
                        value={CompanyName}
                      />
                    </div>
                    {CompanyNameErr && (
                      <p style={{ color: "red" }}>Please Enter Company Name</p>
                    )}
                    <div className={styles.formField}>
                      <label>Address</label>
                      <input
                        type="text"
                        onChange={handleAddress}
                        value={Address}
                      />
                      {AddressErr && (
                        <p style={{ color: "red" }}>Please Enter Address</p>
                      )}
                    </div>
                    <div className={styles.twoColumn}>
                      <div className={styles.formField}>
                        <label>State</label>
                        <input
                          type="text"
                          onChange={handleState}
                          value={State}
                        />
                        {StateErr && (
                          <p style={{ color: "red" }}>Please Enter State</p>
                        )}
                      </div>
                      <div className={styles.formField}>
                        <label>City</label>
                        <input type="text" onChange={handleCity} value={City} />
                        {CityErr && (
                          <p style={{ color: "red" }}>Please Enter City</p>
                        )}
                      </div>
                    </div>
                    <div className={styles.formField}>
                      <label>Zip/Postal Code</label>
                      <input
                        type="number"
                        maxLength={7}
                        onChange={handlePostalCode}
                        value={PostalCode}
                      />
                      {PostalCodeErr && (
                        <p style={{ color: "red" }}>Please Enter postalCode</p>
                      )}
                    </div>
                    <div className={styles.formField}>
                      <label>GST NUMBER</label>
                      <input
                        type="text"
                        onChange={handleGSTNumber}
                        value={GSTNumber}
                      />
                      {GSTNumberErr && (
                        <p style={{ color: "red" }}>
                          Please Enter valid GSTNumber
                        </p>
                      )}
                    </div>
                    <div className={styles.twoColumn}>
                      <div className={styles.formField}>
                        <label>Email Address</label>
                        <input
                          type="text"
                          onChange={handleEmail}
                          value={Email}
                          name="user_email"
                        />
                        {EmailErr && (
                          <p style={{ color: "red" }}>Please Enter Email</p>
                        )}
                      </div>
                      <div className={styles.formField}>
                        <label>Telephone/Mobile No</label>
                        <input
                          type="number"
                          maxLength={10}
                          onChange={handlePhone}
                          value={Phone}
                        />
                        {PhoneErr && (
                          <p style={{ color: "red" }}>Please Enter Phone No</p>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${styles.formField} ${styles.formFieldCheckbox}`}
                    >
                      <input
                        checked={isDifferentAddress}
                        type="checkbox"
                        onChange={handleChekBox}
                        id="shipping"
                        name="shipping"
                      />
                      <label htmlFor="shipping">
                        Ship To Different Address
                      </label>
                    </div>
                    {isDifferentAddress && (
                      <React.Fragment>
                        <div className={styles.formField}>
                          <label>Company</label>
                          <input
                            type="text"
                            onChange={handleCompanyNameTwo}
                            value={CompanyNameTwo}
                          />
                           {CompanyNameTwoErr && (
                      <p style={{ color: "red" }}>Please Enter Company Name</p>
                    )}
                        </div>
                        <div className={styles.formField}>
                          <label>Address</label>
                          <input
                            type="text"
                            onChange={handleAddressTwo}
                            value={AddressTwo}
                          />
                          {AddressTwoErr && (
                      <p style={{ color: "red" }}>Please Enter Adress</p>
                    )}
                        </div>
                        <div className={styles.twoColumn}>
                          <div className={styles.formField}>
                            <label>State</label>
                            <input
                              type="text"
                              onChange={handleStateTwo}
                              value={StateTwo}
                            />
                            {StateTwoErr && (
                      <p style={{ color: "red" }}>Please Enter State</p>
                    )}
                          </div>
                          <div className={styles.formField}>
                            <label>City</label>
                            <input
                              type="text"
                              onChange={handleCityTwo}
                              value={CityTwo}
                            />
                            {CityTwoErr && (
                      <p style={{ color: "red" }}>Please Enter city</p>
                    )}
                          </div>
                        </div>
                        <div className={styles.formField}>
                          <label>Zip/Postal Code</label>
                          <input
                            type="text"
                            onChange={handlePostalCodeTwo}
                            value={PostalCodeTwo}
                          />
                          {PostalCodeTwoErr && (
                      <p style={{ color: "red" }}>Please Enter PinCode</p>
                    )}
                        </div>
                        <div className={styles.formField}>
                          <label>GST NUMBER</label>
                          <input
                            type="text"
                            onChange={handleGSTNumberTwo}
                            value={GSTNumberTwo}
                          />
                           {GSTNumberTwoErr && (
                      <p style={{ color: "red" }}>Please Enter Valid Gst no</p>
                    )}
                        </div>
                        <div className={styles.twoColumn}>
                          <div className={styles.formField}>
                            <label>Email Address</label>
                            <input
                              type="text"
                              onChange={handleEmailTwo}
                              value={EmailTwo}
                            />
                            {EmailTwoErr && (
                      <p style={{ color: "red" }}>Please Enter Email</p>
                    )}
                          </div>
                          <div className={styles.formField}>
                            <label>Telephone/Mobile No</label>
                            <input
                              type="text"
                              onChange={handlePhoneTwo}
                              value={PhoneTwo}
                            />
                            {PhoneTwoErr && (
                      <p style={{ color: "red" }}>Please Enter Phone no</p>
                    )}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  {/* //shipping METHOD */}
                  {/* <div className={styles.shippingMethod}>
                    <div className={styles.shippingAddressName}>
                      <h3>SHIPPING METHOD </h3>
                    </div>
                    <div className={styles.shippingDetails}>
                    </div>
                  </div> */}
                  {/* //payment METHOD */}
                  {/* <div className={styles.paymentMethod}>
                    <div className={styles.paymentAddressName}>
                      <h3>PAYMENT METHOD </h3>
                    </div>
                    <div
                      className={`${styles.formField} ${styles.formFieldCheckbox}`}
                    >
                      <input type="checkbox" id="credit" name="credit" />
                      <label htmlFor="credit">Credit Card</label>
                    </div>
                    <div
                      className={`${styles.formField} ${styles.formFieldCheckbox}`}
                    >
                      <input
                        type="checkbox"
                        id="netbanking"
                        name="netbanking"
                      />
                      <label htmlFor="netbanking">Net Banking</label>
                    </div>
                  </div> */}
                </div>
              </form>
              <div className={styles.orderReview}>
                <div>
                  <div className={styles.proceedBtnContainer}>
                    <button
                      onClick={buttonHnadler}
                      className={styles.proceedBtn}
                      
                    >
                      Proceed For payment{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
