export const regexType = {
    'NO_SPECIAL_CHAR': /^[a-z\d\-_\s]+$/i,
    "ALPHA_NUMERIC": /[^a-z0-9]/gi,
    "ALPHA_NUMERIC_WITH_SPACE": /[^a-z0-9\s]/gi,
    "ALPHA_ONLY": /^[a-zA-Z]*$/,
    "ALPHA_SPACE_ONLY": /[^a-z\s]/gi,
    "ALPHA_NUMERIC_SPACE_DOT_ONLY": /[^a-z0-9.\s]/gi,
    //on request from TNRTP -login validation changed for bankers and staff
    //"ALPHA_WITH_UNDERSCORE": /(^[A-Z])*[A-Z_]+[A-Z]+$/gi,
    "ALPHA_WITH_UNDERSCORE": /^[A-Za-z0-9_]*$/,
    "ALPHA_NUMERIC_WITH_UNDERSCORE_HYPHEN": /[^a-z0-9-_]/gi,
    "ALPHA_WITH_UNDERSCORE_HYPHEN_SPACE": /[^a-z-_\s]/gi,
    "ALPHA_NUMERIC_WITH_UNDERSCORE_HYPHEN_SPACE": /[^a-z0-9-_\s]/gi,
    "ALPHA_NUMERIC_WITH_UNDERSCORE_COMMA_HYPHEN": /[^a-z0-9-,_\s]/gi,
    "ALPHA_NUMERIC_WITH_CERTAIN_SPECIAL_CHAR": /^((?!\s)[a-zA-Z0-9\n \-&_'.,();:\/]*)?$/,
    "ALPHA_NUMERIC_WITH_CERTAIN_SPECIAL_CHAR_EXCLUDE_COMMAS": /^((?!\s)[a-zA-Z0-9\n \-&_'.();:\/]*)?$/,
    "ALPHA_NUMERIC_BK_AND_FW_SLASH_SPACE_AND_DOT": /^((?!\s)[a-z./\d\s\\]*)$/,
    "ALPHA_NUMERIC_BK_AND_FW_SLASH_SPACE_HYPHEN_AND_DOT": /^((?!\s)[a-zA-Z0-9./\-d\s\\]*)$/,
    "NUMERIC_ONLY": /^\d{0,10}$/,
    "PUBLI_LOGIN_VALIDATION": /^(?=.?[A-Z])(?=.?[a-z])(?=.?[0-9])(?=.?[#?!@$&.*-]).{8,16}$/,
    "NAME_VALIDATION": /^[A-Za-z.\s]+$/,
    "ALPHA_NUMERIC_WITH_CERTAIN_SPECIAL_CHAR_PUBLIC": /^[ A-Za-z0-9\s./\\-]*$/,
    "ALPHA_SPACE_DOT_ONLY": /[^A-Za-z.\s]/gi,
    "NUMBERS_ONLY": /[^0-9]/gi,
    "GREATER_THAN_ZERO": /^(0*[1-9][0-9](\.[0-9]+)?|0+\.[0-9][1-9][0-9]*)$/,
    "TA_EN_ALPHA_NUMERIC_ONLY": /[^a-z\u0b80-\u0bff0-9]/gi,
    "TA_EN_ALPHA_SPACE_ONLY": /[^a-z\u0b80-\u0bff\s]/gi,
    "TA_EN_ALPHA_NUMERIC_SPACE_DOT_ONLY": /[^a-z\u0b80-\u0bff0-9.\s]/gi,
    "PAN_NUMBER_VALIDATION": /([A-Z]){5}([0-9]){4}([A-Z]){1}$/gi,
    "GST_NUMBER_VALIDATION": /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/gi,
    "http_link_valid": /^http[s]?:\/\/(www\.)?(.)?\/?(.)/,
    "MULTI_LIN_ALPHA_NUMERIC_WITH_CERTAIN_SPECIAL_CHAR_PUBLIC": /^[ A-Za-z\u0b80-\u0bff0-9\s./\\-]*$/,
    "MULTI_LIN_ALPHA_SPACE_DOT_ONLY": /[^A-Za-z\u0b80-\u0bff.\s]/gi,
    "MULTI_LIN_ALPHA_NUMERIC_ONLY": /[^a-z\u0b80-\u0bff0-9]/gi,
    "MULTI_LIN_ALPHA_SPACE_ONLY": /[^a-z\u0b80-\u0bff\s]/gi,
    "MULTI_LIN_ALPHA_NUMERIC_SPACE_DOT_ONLY": /[^a-z\u0b80-\u0bff0-9.\s]/gi,
    "PINCODE": /^[1-9][0-9]{5}/g,
    "ALPHA_NUMERIC_BK_AND_FW_SLASH_SPACE_HYPEN_BRACKETS_AND_DOT": /^((?!\s)[A-Za-z.()-/\d\s\\]*)$/,
    "ALPHA_NUMERIC_BK_FW_SLASH_HYPHEN_SPACE": /^[ A-Za-z0-9/\\-\s]*$/,
    "ALPHA_NUMERIC_BK_FW_SLASH_HYPHEN_SPACE_DOT": /^[ A-Za-z0-9./\\-\s]*$/,
    "UPPER_CASE_ALPHA_AND_NUMERIC": /^[A-Z0-9]*$/,
    "SHOULD_NOT_ACCEPT_COMMA": /[0-9\,]/g,
    "UPPER_LOWER_ALPHA_NUMERIC": /^[A-Za-z0-9]*$/,
    "TAN_NUMBER": /^([A-Z]{4,4})([0-9]{5,5})([A-Z]{1,1})*$/,
    "GST_NUMBER": /^([0-9]{2,2})([A-Z]{5,5})([0-9]{4,4})([A-Z]{1,1})([0-9]{1,1})([A-Z]{2,2})*$/,
    "IFSC_CODE": /^[A-Z]{4}0[A-Z0-9]{6}$/,
    "CSS_ID_VALIDATION": /^([A-Z]{3})([0-3]{1})([0-9]{1})-([0-9]{3})-([A-Z]{4})-CSS([0-9]{4})*$/,
    "CFS_ID_VALIDATION": /^([A-Z]{3})([0-3]{1})([0-9]{1})-([0-9]{3})-([A-Z]{4})-CFS([0-9]{4})*$/,
    "UPPER_LOWER_ALPHA_NUMERIC_SPACE": /^[A-Za-z0-9\s]*$/,
    "AADHAR_NUMBER": /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/,
    "VOTER_ID": /^([a-zA-Z]){3}([0-9]){7}?$/,
    "RATION_CARD": /^([0-9]){2}([a-zA-Z]){2}([0-9]){3}([a-zA-Z]){2}?$/,
    "DRIVING_LICENCE": /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
    "NAME_REGEX": /^[A-Za-z.\s]*$/,
    "UDYAM_NUMBER_VALIDATION": /^(UDYAM-)([A-Z]{2,2})(-)([0-9]{2,2})(-)([0-9]{7,7})*$/,
    "PHONE_NUMBER" : /^[6-9]\d{9}$/,
    "EMAIL_ID":/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ 
}