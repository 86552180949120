import React, { useRef } from "react";
import styles from "./FormModal.module.css";
import {AiOutlineClose} from "react-icons/ai";
import emailjs from '@emailjs/browser';

function FormModal(props) {
  const form = useRef();

  const sendEmail = (e) =>{
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_fbou472', form.current, '0ugtleXRRE5RDC6nw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()

  }




  return (
    <div>
      <div className={styles.backdrop}>
        <div className={styles.modal}>
          <header className={styles.header}>
            <h2>Fields requaired</h2>
            <p align='end'><AiOutlineClose onClick={props.onClick} size={25}/></p>
          </header>
          <div className={styles.content}>
          Please fill all the details first
          </div>
          <footer className={styles.actions}>
            <button  type="submit" onClick={props.onClick}>CLOSE</button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default FormModal;
