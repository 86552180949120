import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProductPage from "./Product/Index";
import Cart from "./Cart/Index";
import HeaderBox from "./Home/HeaderBox";
import Header from "./Home/Header";
import Checkout from "./Checkout/Index";
import OrderProduct from "./OrderProduct/OrderProduct";
import CartProvider from "./store/CartProvider";
const HomePage = lazy(() => import("./Home/Index"));
// const CompanyPage = lazy(() => import("./Company/Index"));
const VDIToolDiscs = lazy(() =>
  import("./Product/ToolDiscs/VDIToolDiscs/Index")
);
const SlottedToolDiscs = lazy(() =>
  import("./Product/ToolDiscs/SlottedToolDiscs/Index")
);


const App = () => {
  const [navigationId, setNavigationId] = useState(1);
  const [Product, setProduct] = useState("");
  const [ProductData, setProductData] = useState({});
  const [emptyCart, setEmptyCart]= useState(false)

  return (
    <>
    {/* <CartProvider> */}
    {window.innerWidth>1000 && <div style={{position:"absolute" ,zIndex:'1' , bottom:"0" , left:"50%"}}>
        <h6><strong>Designed</strong> and <strong>Developed</strong> by <strong style={{color:"#f05a29"}}>DIGIKIT</strong></h6>
        </div>}
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <HomePage
                  setNavigationId={setNavigationId}
                  Product={Product}
                  setProduct={setProduct}
                  ProductData={ProductData}
                  setProductData={setProductData}
                />
              )}
            />
            <Route
              exact
              path="/home"
              render={() => (
                <HomePage
                  setNavigationId={setNavigationId}
                  Product={Product}
                  setProduct={setProduct}
                  ProductData={ProductData}
                  setProductData={setProductData}
                />
              )}
            />
            
            <Route
              exact
              path="/product"
              render={() => <ProductPage
                Product={Product}
                setProduct={setProduct}
                ProductData={ProductData}
                setProductData={setProductData}
               />}
            />
            <Route
              exact
              path="/cart"
              // render={() => <ProductPage Product={Product} />}
              render={() => <Cart />}
            />
            <Route
              exact
              path="/checkout"
              // render={() => <ProductPage Product={Product} />}
              render={() => <Checkout />}
            />
            <Route
              exact
              path="/orderproduct"
              // render={() => <ProductPage Product={Product} />}
              render={() => <OrderProduct/>}
            />
          </Switch>
        </Suspense>
      </Router>
      {/* </CartProvider> */}
    </>
  );
};

export default App;
