import { useHistory } from "react-router-dom";
import logo from "./assets/logo.png";
import logo_1 from "./assets/top-left.png";
import borderIcon from "./assets/white-diam.png";
import { AiOutlineClose } from "react-icons/ai";

import styles from "../styles/HeaderBox.module.css";
import { AiOutlineShoppingCart, AiOutlineFilePdf } from "react-icons/ai";
import { useContext } from "react";
import CartContext from "../store/cart-context";
import { useState } from "react";
export default function HeaderBox(props) {
  let history = useHistory();
  const handleCart = () => {
    history.push("/cart");
  };
  let mainArrayItems = JSON.parse(localStorage.getItem("mainarry"));
  let initialValue = 0;

  if (mainArrayItems !== null) {
    let mainArray = JSON.parse(localStorage.getItem("mainarry"));

    mainArray.map(
      (previousValue) => (initialValue += +previousValue.articleObjCount)
    );
    console.log(initialValue);
  }

  return (
    <>
      <div className={styles.headerBoxMainContainer}>
        <div className={styles.homePageBorderLeft}></div>
        <div className={styles.homePageBorderTop}></div>
        <img src={borderIcon} className={styles.homePageIcon2} />
        <img src={borderIcon} className={styles.homePageIcon1} />
        <div className={styles.headerBox}>
          <a href="https://shop.sphoorti.in/">
          <img src={logo} />
          </a>
        </div>
        <div className={styles.menu_btn} onClick={props.onClick}>
          <div className={styles.btn_line}></div>
          <div className={styles.btn_line}></div>
          <div className={styles.btn_line}></div>
        </div>
        {/* <div className={styles.menu_btn} onClick={closeNavBar}>
          <div className={styles.btn_line}></div>
          <div className={styles.btn_line}></div>
          <div className={styles.btn_line}></div>
        </div> */}

        {props.togglex && (
          <div className={styles.backgroundnavbar}>
            <div className={styles.mainwebsite}>
              <a
                href={
                  window.location.href == "https://shop.sphoorti.in/"
                    ? "https://sphoorti.com/"
                    : "https://shop.sphoorti.in/"
                }
              >
                <button>
                  {window.location.href == "https://shop.sphoorti.in/"
                    ? "Go to Main Website"
                    : "Home"}
                </button>
              </a>
            </div>
            {/* <div className={styles.cart_items}>
              <button onClick={handleCart}>
                &nbsp;&nbsp; Cart <AiOutlineShoppingCart color="white" />
              </button>
              <div>
                {localStorage.getItem("mainarry")
                  ? JSON.parse(localStorage.getItem("mainarry")).length
                  : 0}
              </div>
            </div> */}
          </div>
        )}
        {!props.togglex && (
          <div className={styles.backgroundnavbar}>
            <div className={styles.mainwebsite}>
              <a
                href={
                  window.location.href == "https://shop.sphoorti.in/"
                    ? "https://sphoorti.com/"
                    : "https://shop.sphoorti.in/"
                }
              >
                <button>
                  {window.location.href == "https://shop.sphoorti.in/"
                    ? "Go to Main Website"
                    : "Home"}
                </button>
              </a>
            </div>
            <div >
              {/* <button onClick={handleCart}>
                &nbsp;&nbsp; Cart <AiOutlineShoppingCart color="white" />
              </button> */}
              <div>
                {/* {localStorage.getItem("mainarry")
                  ? JSON.parse(localStorage.getItem("mainarry")).length
                  : 0} */}
                {/* {initialValue > 0 ? initialValue : 0} */}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <HeaderSlidersItem id={id} /> */}
    </>
  );
}
